<template>
  <div class="header">
    <div class="header_body">
      <div class="logo">
        <!-- <a href="http://kattgatt.com"> -->
        <img src="@/assets/images/logo.png" alt="" />
        <!-- </a> -->
      </div>
      <nav class="nav">
        <template v-for="navItem in navLinkList" :key="navItem.label">
          <dropDownVue v-if="navItem.dropDown">
            <RouterLink class="nav-item_link" :to="navItem.path"
              >{{ navItem.label }}
            </RouterLink>

            <template v-slot:dropdown_wrap>
              <div class="category-list">
                <RouterLink
                  v-for="navItemChildren in navItem.children"
                  :key="navItemChildren.label"
                  :to="navItemChildren.path"
                  class="category"
                >
                  {{ navItemChildren.label }}
                </RouterLink>
              </div>

              <template v-if="navItem.label == 'AI产品中心'">
                <div class="category-list">
                  <div
                    v-for="(category, index) in productCategoryList"
                    :key="category.id.toString()"
                    class="category"
                    @mouseover="onHover(index)"
                  >
                    {{ category.categoryName }}
                  </div>
                </div>
                <swiper
                  :slides-per-view="5"
                  :space-between="50"
                  style="width: 1200px"
                  :modules="[Navigation]"
                  @swiper="setControlledSwiper"
                  :navigation="true"
                >
                  <swiper-slide
                    v-for="productInfo in currentProductDetailList"
                    :key="productInfo.id"
                  >
                    <a
                      class="product_item wow animate__animated animate__fadeInUp"
                      @click="onClickProduct(productInfo)"
                    >
                      <div class="product_avatar">
                        <img :src="productInfo.avatar" alt="" />
                      </div>
                      <span class="product_name">
                        {{ productInfo.goodsName }}
                      </span>
                    </a>
                  </swiper-slide>
                </swiper>
              </template>
            </template>
          </dropDownVue>
          <a
            class="scaleModel"
            v-else-if="navItem.label == 'AI大模型'"
            target="_blank"
            :href="navItem.path"
            >{{ navItem.label }}</a
          >
          <a
            class="scaleModel"
            :class="`nav-item_link ${navItem.className}`"
            v-else-if="navItem.type == 'link'"
            target="_blank"
            :href="navItem.path"
          >
            <img :src="navItem.imgIcon" alt="" />
            {{ navItem.label }}
          </a>

          <RouterLink
            :class="`nav-item_link ${navItem.className}`"
            :to="navItem.path"
            v-else
            >{{ navItem.label }}
          </RouterLink>
        </template>
      </nav>
      <togglerButtonVue
        v-model:toggler-status="togglerStatus"
      ></togglerButtonVue>
    </div>
  </div>
  <nav class="nav-h5" v-show="togglerStatus" @click="onClickNav">
    <RouterLink
      v-for="navItem in navLinkList"
      :class="`nav-item_link ${navItem.className}`"
      :to="navItem.path"
      :key="navItem.label"
      >{{ navItem.label }}
    </RouterLink>
  </nav>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import togglerButtonVue from "./component/togglerButton.vue";
import dropDownVue from "@/components/dropDown.vue";
import type {
  ProductCategoryList,
  ProductDetailRecords,
  ProductDetailRecordsList,
} from "@/views/productCenter/productCenter";
import {
  queryProductCategoryList,
  queryProductDetail,
} from "@/api/productCenter";
import type { PaginationResult } from "@/request/request";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useRouter } from "vue-router";

const router = useRouter();
const controlledSwiper: any = ref(null);
const setControlledSwiper = (swiper: any) => {
  controlledSwiper.value = swiper;
};

const productCategoryList = ref([] as ProductCategoryList);

const AllproductDetailList = ref([] as ProductDetailRecordsList);

const currentProductDetailList = ref([] as ProductDetailRecords);

const togglerStatus = ref(false);

const navLinkList = reactive([
  {
    path: "/homePage",
    label: "首页",
  },

  // {
  //   path: "/dataBusiness",
  //   label: "数字商业",
  // },
  {
    path: "/productCenter",
    label: "AI产品中心",
    dropDown: true,
  },
  {
    path: "https://assistant.kattgatt.com/",
    label: "AI大模型",
  },
  // {
  //   path: "/videoCenter",
  //   label: "视频中心",
  // },
  {
    path: "/businessCooperation",
    label: "城市合伙人",
    dropDown: true,
    children: [
      {
        label: "经销代理",
        path: "/businessCooperation",
      },
      {
        // label: "加盟商",
        label: "城市展厅",
        path: "/businessCooperation/showRoom",
      },
    ],
  },
  {
    path: "/about",
    label: "关于我们",
    dropDown: true,
    children: [
      {
        label: "公司简介",
        path: "/about/companyIntroduce",
      },
      {
        label: "新闻动态",
        path: "/about/newList",
      },
      {
        label: "加入我们",
        path: "/about/joinUs",
      },
      {
        label: "联系我们",
        path: "/about/contactUs",
      },
    ],
  },
  {
    path: "/newProductCenter",
    label: "新品中心",
  },
  {
    path: "https://ai.kattgatt.com",
    label: "AI专利助手",
    className: "ai-patent-assistant",
    imgIcon: new URL(
      "../assets/images/Property1=Variant3@3x.png",
      import.meta.url
    ).href,
    type: "link",
  },
]);

const onClickNav = () => {
  togglerStatus.value = false;
};

const onHover = (index: number) => {
  currentProductDetailList.value = AllproductDetailList.value[index];
};

const onClickProduct = (productInfo: any) => {
  router.push({
    path: "/productDetailPage",
    query: {
      productInfo: encodeURI(JSON.stringify(productInfo)),
    },
  });
};

onMounted(() => {
  queryProductCategoryList().then((res) => {
    productCategoryList.value = res.result as ProductCategoryList;
    Promise.all(
      productCategoryList.value.map((productCategory) =>
        queryProductDetail(productCategory.id)
      )
    ).then((responseList) => {
      AllproductDetailList.value = responseList.map((response) => {
        return (response.result as PaginationResult)
          .records as ProductDetailRecords;
      });
      onHover(0);
    });
  });
});
</script>

<style lang="scss" scoped>
.category-list {
  display: flex;
  justify-content: center;
  font-size: transformRem(18px);
  width: transformRem(1200px);
  margin: auto;
  line-height: transformRem(75px);

  .category {
    margin: 0 10px;
    border-bottom: rgba($color: #000000, $alpha: 0) 2px solid;

    &:hover {
      border-bottom: #0441b3 2px solid;
    }
  }
}

@media screen and (max-width: 1200px) {
  .nav {
    display: none;
  }
}

.header {
  display: flex;
  justify-content: center;
  background-color: $black_bg;

  .header_body {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: transformRem($pc_min_width);
    line-height: transformRem(75px);

    .product_item {
      flex-shrink: 0;
      width: calc(transformRem(300px));
      // height: calc(transformRem(200px));
      margin-left: transformRem(18px);
      margin-right: transformRem(18px);
      margin-bottom: transformRem(60px);
      text-align: center;

      .product_avatar {
        background-color: #ececec;
        width: 100%;
        // min-height: 153px;
        height: 153px;
        margin-bottom: transformRem(18px);

        img {
          width: 100%;
        }
      }

      .product_name {
        // font-size: transformRem(24px);
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .header_body {
      padding: 0 16px;
    }
  }
}

.swiper {
  line-height: 1.5;
}

.logo {
  width: transformRem(209px);
  // width: transformRem(313px);
  // height: transformRem(22.5px);
  // margin: transformRem(15px) 0;

  img {
    width: 100%;
    // height: 100%;
  }
}

.nav {
  margin: 0 transformRem(-30px);

  .nav-item_link {
    display: block;
    height: transformRem(75px);
    // height: transformRem(45px);
    // line-height: transformRem(45px);
    font-size: transformRem(18px);
    margin: 0 transformRem(20px);
    padding: 0 5px;
    color: $gray_secondary_font;
    border-bottom: 5px solid $black_bg;

    &:hover {
      color: #fff;
      border-bottom: 5px solid #0441b3;
    }
  }

  a.ai-patent-assistant {
    color: rgba($color: #000000, $alpha: 0);
    background: linear-gradient(180deg, #ff1d00 17.31%, #f5d21b 100%);
    background-clip: text;
    font-family: Source Han Sans CN;
    font-size: 17.5px;
    font-weight: bolder;
    text-align: left;
    img {
      width: 24px;
    }

    &:hover {
      color: rgba($color: #000000, $alpha: 0);
      background: url("../assets/images/Component1@3x.png") no-repeat;
      background-size: contain;
      background-position: center;
      img {
        opacity: 0;
      }
    }
  }

  .scaleModel {
    display: block;
    height: transformRem(75px);
    // height: transformRem(45px);
    // line-height: transformRem(45px);
    font-size: transformRem(18px);
    margin: 0 transformRem(20px);
    padding: 0 5px;
    color: $gray_secondary_font;
    border-bottom: 5px solid $black_bg;

    &:hover {
      color: #fff;
      border-bottom: 5px solid #0441b3;
    }
  }
}

.nav-h5 {
  position: absolute;
  width: 100%;
  background-color: $black_bg;
  color: $gray_secondary_font;
  z-index: 9;

  .nav-item_link {
    display: block;
    font-size: transformRem(32px);
    line-height: transformRem(72px);
    padding: 0 16px;
    border-top: 1px solid $gray_normal_font;
  }
}

@media screen and (min-width: 1200px) {
  .nav-h5 {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .nav {
    display: none;
  }

  .logo {
    width: transformRem(209px);
    // width: transformRem(313px);
    // height: transformRem(22.5px);
    // margin: transformRem(15px) 0;

    img {
      width: 100%;
      // height: 100%;
    }
  }

  .nav-h5 {
    display: block;
  }

  .header_body {
  }
}
</style>
