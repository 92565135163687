<template>
  <div class="dropdown">
    <slot></slot>
    <div class="dropdown_wrap" ref="dropdownWrapElement">
      <slot name="dropdown_wrap"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
router.beforeEach(() => {
  setTimeout(() => {
    dropdownWrapElement.value.style.display = "none";
  }, 500);

  setTimeout(() => {
    dropdownWrapElement.value.style = "";
  }, 1000);
});
const dropdownWrapElement = ref();
</script>

<style lang="scss" scoped>
.dropdown {
  position: unset;
}

.dropdown:hover .dropdown_wrap {
  display: block;
  box-shadow: 0 1px 4px 0 #ccc;
  padding-bottom: 20px;
}
.dropdown_wrap:hover {
  display: block;
}
.dropdown_wrap {
  display: none;
  position: absolute;
  // top: transformRem(97.5px);
  top: transformRem(72px);
  left: 0;
  // height: 300px;
  width: 100%;
  background-color: #fff;
  z-index: 9;
}
</style>
