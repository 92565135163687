<template>
  <div class="footer">
    <div class="footer_body">
      <div class="footer_text">
        <h1>让家庭、个人大数据变成财富</h1>
      </div>
      <div class="barkWiter"></div>
      <nav class="nav">
        <div v-for="cloumn in navData" :key="cloumn.label" class="nav-column">
          <div class="nav-column_title wow animate__animated animate__fadeInUp">
            {{ cloumn.label }}
          </div>
          <div class="nav-column_body">
            <template v-for="navItem in cloumn.children" :key="navItem.label">
              <a
                class="nav-item_link wow animate__animated animate__fadeInUp"
                :href="navItem.path"
                v-if="navItem.type === 'link'"
                target="_blank"
              >
                {{ navItem.label }}
              </a>

              <RouterLink
                v-else
                class="nav-item_link wow animate__animated animate__fadeInUp"
                :to="navItem.path"
              >
                {{ navItem.label }}
              </RouterLink>
            </template>
          </div>
        </div>
      </nav>

      <div class="contact">
        <div class="contact_body">
          <div class="contact-info wow animate__animated animate__fadeInUp">
            <div class="contact-info_title">联系我们</div>
            <div
              v-for="contactInfoItem in contactInfoData"
              class="contact-info_item"
              :key="contactInfoItem"
            >
              {{ contactInfoItem }}
            </div>
          </div>
          <div class="contact-qrcode_list">
            <div
              v-for="contactQrcode in contactQrcodeData"
              :key="contactQrcode.label"
              class="contact-qrcode_list-item wow animate__animated animate__fadeInUp"
            >
              <img :src="contactQrcode.imgPath.toString()" alt="" />
              <label>{{ contactQrcode.label }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="webseit-info wow animate__animated animate__fadeInUp">
        <div class="copyright wow animate__animated animate__fadeInUp">
          版权所有©2019 Kattgatt，Inc。保留所有权利。<a
            href="https://beian.miit.gov.cn/"
            target="_blank"
          >
            京ICP备2023010658号
          </a>

          <a
            style="display: flex"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502053531"
          >
            <img
              src="@/assets/police-insignia.jpg"
              style="float: left; height: 100%"
            />
            <p style="">京公网安备 11010502053531号</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
const navData = reactive([
  {
    label: "关于我们",
    children: [
      {
        label: "公司简介",
        path: "/about/companyIntroduce",
        type: "route",
      },
      // {
      //   label: "企业文化",
      //   path: "#",
      // },
    ],
  },
  {
    label: "新闻中心",
    children: [
      {
        label: "新闻消息",
        path: "/about/newList",
        type: "route",
      },
      // {
      //   label: "行业信息",
      //   path: "#",
      // },
    ],
  },
  // {
  //   label: "解决方案",
  //   children: [
  //     {
  //       label: "数字家庭方案",
  //       path: "/dataFamily",
  //       type: "route",
  //     },
  //     {
  //       label: "数字校园方案 ",
  //       path: "/dataSchool",
  //       type: "route",
  //     },
  //     {
  //       label: "数字社区方案 ",
  //       path: "/dataCommunity",
  //       type: "route",
  //     },
  //     {
  //       label: "数字楼宇方案 ",
  //       path: "/dataBuilding",
  //       type: "route",
  //     },
  //     {
  //       label: "数字办公方案 ",
  //       path: "/dataOffice",
  //       type: "route",
  //     },
  //   ],
  // },
  {
    label: "产品中心",
    children: [
      {
        // label: "数字中控",
        label: "AI家庭超算主机",
        path: "/productCenter",
        type: "route",
      },
      {
        // label: "数字照明",
        label: "AI智慧照明",
        path: "/productCenter",
        type: "route",
      },
      {
        // label: "数字开关",
        label: "AI智慧影音",
        path: "/productCenter",
        type: "route",
      },
      {
        // label: "数字门锁",
        label: "AI智慧开关",
        path: "/productCenter",
        type: "route",
      },
      {
        label: "更多产品",
        path: "/productCenter",
        type: "route",
      },
    ],
  },
  {
    label: "商务合作",
    children: [
      {
        label: "经销代理",
        path: "/businessCooperation",
        type: "route",
      },
      {
        label: "城市展厅",
        path: "/businessCooperation/showRoom",
        type: "route",
      },
    ],
  },
  {
    label: "招贤纳士",
    children: [
      {
        label: "职位信息",
        path: "/about/joinUs",
        type: "route",
      },
    ],
  },
  {
    label: "更多工具",
    children: [
      {
        label: "工具下载",
        path: "/Download",
      },
      {
        label: "CRM",
        path: "http://www.kattgatt.com:5001/",
        type: "link",
      },
    ],
  },
]);
const contactInfoData = reactive([
  // "电话：(0755) 8273 5192",
  "电话：400 0256 889",
  "商务合作：136 5114 4947",
  "媒体合作：134 3926 0906",
  "联系地址：北京市朝阳区将台乡酒仙桥北路乙10号院2号楼星地中心B座10层1005室",
  "公司名称：北京卡特加特人工智能科技有限公司",
]);

const contactQrcodeData = reactive([
  {
    imgPath: new URL("@/assets/images/image.png", import.meta.url).href,
    label: "公众号",
  },
  {
    imgPath: new URL("@/assets/images/WechatIMG23.jpg", import.meta.url),
    label: "APP下载",
  },
  // {
  //   imgPath: new URL("@/assets/images/android.png", import.meta.url),
  //   label: "安卓",
  // },
  // {
  //   imgPath: new URL("@/assets/images/android-pad.png", import.meta.url),
  //   label: "安卓平板",
  // },
]);
</script>

<style lang="scss" scoped>
.footer {
  background-color: $black_bg;

  .footer_body {
    width: transformRem($pc_min_width);
    padding-top: transformRem(70px);
    padding-bottom: transformRem(124px);
    color: #ffffff;
    margin: auto;
    .footer_text {
      width: 100%;
      text-align: center;
      h1 {
        margin-bottom: transformRem(10px);
        letter-spacing: transformRem(14px);
        font-weight: bold;
        font-size: transformRem(64px);
      }
    }

    .barkWiter {
      background: #ffffff;
      width: 100%;
      height: 50px;
      margin-bottom: 50px;
    }
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 54px;

  .nav-column {
    flex-grow: 1;
    flex-shrink: 1;

    &:nth-of-type(1) {
      flex-grow: 1.6;
    }

    &:nth-of-type(7) {
      flex-grow: 0;
    }

    .nav-column_title {
      margin-bottom: transformRem(24px);
      font-size: transformRem(20px);
      color: $white_font;
    }
  }

  @media screen and (max-width: 1200px) {
    .nav-column {
      width: 33.33%;
      text-align: center;
      margin-bottom: transformRem(48px);

      &:nth-of-type(1) {
        flex-grow: 1;
      }

      &:nth-of-type(6) {
        flex-grow: 1;
      }

      .nav-item_link {
        width: auto;
      }
    }
  }

  .nav-item_link {
    display: block;
    width: max-content;
    margin-bottom: 10px;
    color: $gray_normal_font;

    &:hover {
      color: $white_font;
    }
  }
}

.contact {
  margin-bottom: transformRem(60px);

  .contact_body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .contact-info_title {
      font-size: transformRem(20px);
      color: $white_font;
    }

    .contact-info_item {
      color: $gray_normal_font;
    }

    .contact-qrcode_list {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: right;

      .contact-qrcode_list-item {
        margin-left: transformRem(12px);
        text-align: center;
        color: $gray_normal_font;
        img {
          width: transformRem(104px);
        }

        img,
        lable {
          display: block;
          margin-bottom: transformRem(16px);
        }
      }
    }
  }
}

.webseit-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid #adadad;
  color: $gray_normal_font;
  padding: transformRem(12px) 0;
}

@media screen and (max-width: 1200px) {
  .footer {
    .footer_body {
      padding-top: transformRem(70px);
      padding-bottom: transformRem(124px);
      padding-left: 16px;
      padding-right: 16px;
      width: transformRem($h5_min_width);
      .footer_text {
        width: 100%;
        text-align: center;
        h1 {
          margin-bottom: transformRem(10px);
          letter-spacing: transformRem(14px);
          font-weight: bold;
          font-size: transformRem(64px);
        }
      }

      .barkWiter {
        background: #ffffff;
        width: 100%;
        height: 50px;
        margin-bottom: 50px;
      }
    }
  }

  .contact {
    .contact_body {
      .contact-qrcode_list {
        margin: 0 -16px;

        .contact-qrcode_list-item {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;

          width: 50%;
          margin: 0px;
          padding: 16px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
