<script setup lang="ts">
import HeaderLayout from "./headerLayout.vue";
import FooterLayout from "./footerLayout.vue";
import { useRoute } from "vue-router";
const route = useRoute();
</script>
<template>
  <HeaderLayout></HeaderLayout>
  <RouterView :key="route.fullPath"></RouterView>
  <FooterLayout></FooterLayout>
</template>
