import { createRouter, createWebHistory, RouterView } from "vue-router";
import mainLayout from "../layout/mainLayout.vue";
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: mainLayout,
      redirect: "/homePage",
      children: [
        {
          path: "/homePage",
          component: () => import("@/views/homePage/homePage.vue"),
        },
        {
          path: "/dataFamily",
          component: () => import("@/views/dataFamilyPage.vue"),
        },
        // {
        //   path: "/videoCenter",
        //   component: () =>
        //     import("@/views/videoCenter/videoCenter.vue"),
        // },
        {
          path: "/newProductCenter",
          component: () => import("@/views/newProductCenter.vue"),
        },
        {
          path: "/dataBusiness",
          component: () => import("@/views/dataBusinessPage.vue"),
        },
        {
          path: "/dataOffice",
          component: () => import("@/views/dataOfficePage.vue"),
        },
        {
          path: "/dataSchool",
          component: () => import("@/views/dataSchoolPage.vue"),
        },
        {
          path: "/dataBuilding",
          component: () => import("@/views/dataBuildingPage.vue"),
        },
        {
          path: "/dataCommunity",
          component: () => import("@/views/dataCommunityPage.vue"),
        },
        {
          path: "/productCenter",
          component: () => import("@/views/productCenter/productCenter.vue"),
        },
        {
          path: "/productDetailPage",
          component: () =>
            import("@/views/productCenter/productDetailPage.vue"),
        },
        {
          path: "/businessCooperation",
          component: RouterView,
          children: [
            {
              path: "/businessCooperation",
              component: () => import("@/views/businessCooperationPage.vue"),
            },
            {
              path: "/businessCooperation/showRoom",
              component: () => import("@/views/showRoom/showRoom.vue"),
            },
          ],
        },
        {
          path: "/about",
          component: RouterView,
          redirect: "/about/companyIntroduce",
          children: [
            {
              path: "/about/companyIntroduce",
              component: () => import("@/views/companyIntroduce.vue"),
            },
            {
              path: "/about/showRoom",
              component: () => import("@/views/showRoom/showRoom.vue"),
            },
            {
              path: "/about/joinUs",
              component: () => import("@/views/joinUsPage.vue"),
            },
            {
              path: "/about/newList",
              component: () => import("@/views/newListPage/newListPage.vue"),
            },
            {
              path: "/about/newsDetail/:id",
              component: () => import("@/views/newsDetail/newsDetailPage.vue"),
            },
            {
              path: "/about/contactUs",
              component: () => import("@/views/contactUsPage.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/Download",
      component: () => import("@/views/DownloadCenter/downloadPage.vue"),
    },
  ],
});

router.beforeEach(() => {
  document.querySelector("html")?.scrollTo({ top: 0 });
});

export default router;
