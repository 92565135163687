<template>
  <div class="navbar-toggler_button" @click="onClickToggle">
    <div
      class="navbar-toggler_icon"
      :class="
        toggleStatus ? 'navbar-toggler_icon_open' : 'navbar-toggler_icon_close'
      "
    >
      <div class="navbar-toggler_icon-line"></div>
      <div class="navbar-toggler_icon-line"></div>
      <div class="navbar-toggler_icon-line"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
const props = defineProps(["togglerStatus"]);
const emit = defineEmits(["update:togglerStatus"]);
watch(props, (value) => {
  toggleStatus.value = value.togglerStatus;
});
const toggleStatus = ref(false);
const onClickToggle = () => {
  toggleStatus.value = !toggleStatus.value;
  emit("update:togglerStatus", toggleStatus.value);
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .navbar-toggler_button {
    display: none;
  }
}

.navbar-toggler_button {
  width: transformRem(56px);
  height: transformRem(40px);
  border: 1px solid $gray_secondary_font;
  border-radius: transformRem(6px);
  padding: transformRem(4px) transformRem(12px);
  .navbar-toggler_icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: transformRem(30px);
    height: transformRem(30px);
    transition: 0.5s;

    .navbar-toggler_icon-line {
      height: 2px;
      background-color: $gray_secondary_font;
      transition: 0.5s;
    }
  }

  .navbar-toggler_icon_close {
    .navbar-toggler_icon-line {
      height: 2px;
      background-color: $gray_secondary_font;
    }
  }

  .navbar-toggler_icon_open {
    width: transformRem(33px);
    .navbar-toggler_icon-line:nth-child(1) {
      transform: rotateZ(28deg);
      transform-origin: left;
    }
    .navbar-toggler_icon-line:nth-child(2) {
      display: none;
    }
    .navbar-toggler_icon-line:nth-child(3) {
      transform: rotateZ(-28deg);
      transform-origin: left;
    }
  }
}
</style>
